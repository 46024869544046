<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the terms below are appropriate to describe the absorption peak corresponding to
        the stretch of the
        <chemical-latex content="O-H" />
        functional group in an alcohol?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA6Q12',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'Sharp', value: 'sharp'},
        {text: 'It forms a double point', value: 'double'},
        {text: 'Weak', value: 'weak'},
        {text: 'Strong', value: 'strong'},
        {text: 'Very broad', value: 'broad'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
